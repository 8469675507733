import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppMode } from '@app/core/models/app-mode.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppModeService implements OnDestroy {
  public _appMode$ = new BehaviorSubject<AppMode>(AppMode.normal);

  prevMode: AppMode = this._appMode$.value;
  subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private _location: Location) { }

  get appMode(): Observable<AppMode> {
    return this._appMode$;
  }

  initAppModeTracking(): void {
    this.activatedRoute?.queryParams?.subscribe((params) => {
      const mode = params?.mode?.toLowerCase() || AppMode.normal;

      if (this._appMode$.value !== mode) {
        this._appMode$.next(mode);
      }
    });
    this.registerRouteChange();
  }

  private registerRouteChange(): void {
    const sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.prevMode = this.router?.getCurrentNavigation()?.previousNavigation?.finalUrl?.queryParams?.mode;

        if (this.prevMode === AppMode.kiosk && event.navigationTrigger === 'popstate') {
          const urlTree = this.router.createUrlTree(['../'], { relativeTo: this.activatedRoute });
          this._location.replaceState(urlTree.toString());
        };
      }

      if (event instanceof NavigationEnd) {
        if (this.prevMode === AppMode.kiosk && !event.urlAfterRedirects.includes(AppMode.kiosk)) {
          const urlTree = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: { mode: this.prevMode }, queryParamsHandling: 'merge' });
          void this.router.navigateByUrl(urlTree.toString(), { replaceUrl: true })
        }
      }
    });
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
